import React from 'react';
import Breadcrumb from '../breadcrumb/index';
import Formulario from '../formulario/index';
import { connect } from 'react-redux';
import Busca from '../buscamanifest/index';
import './sic.css';
import Arquivos from '../arquivos/index';

class SicConteudo extends React.Component {
  render() {
    return (
      <div
        className={
          this.props.isContraste ? 'contraste-display-grid' : 'display-grid'
        }
      >
        <div className="sic-cabecalho">
          <Breadcrumb />
        </div>
        {/* <BoxMessage
          icon="/icon-info.png"
          title="Prezados(as) clientes,"
          subtitle={`O SIC SEBRAE informa que os pedidos registrados entre os dias 21 de dezembro de 2024 e 5 de janeiro de 2025 terão seus prazos suspensos devido ao recesso de final de ano, sendo tratados a partir de 6 de janeiro de 2025.
          </br>
Agradecemos a compreensão e desejamos um próspero Ano Novo.`}
        /> */}
        <div className="display-texto">
          <div className="sic-cabecalho">
            <div>
              <h2>SIC</h2>
              <p>
                O Serviço de Informação ao Cidadão do SEBRAE – SIC permite que
                qualquer pessoa, física ou jurídica, apresente pedidos de acesso
                a informações custodiadas pelo Sistema SEBRAE.
              </p>
              <p>
                Maiores informações veja nossas “Perguntas mais frequentes”
                disponíveis em{' '}
                <a
                  href="#sic-faq"
                  style={
                    this.props.isContraste
                      ? {
                          textDecoration: 'none',
                          fontWeight: 'bold',
                          color: 'yellow',
                        }
                      : {
                          textDecoration: 'none',
                          fontWeight: 'bold',
                          color: '#333',
                        }
                  }
                >
                  FAQ
                </a>
              </p>
            </div>
            <Busca />
          </div>

          <a className="btn-sic" href="#formulario">
            Nova Solicitação
          </a>
          <Arquivos>
            <Formulario id="formulario_manifestacao" />
            <div id="formulario"></div>

            <div className="display-texto">
              <h3>Outras formas de falar com o SIC:</h3>
              <ul>
                <li style={{ paddingBottom: '5px', fontSize: '16px' }}>
                  E-mail: sic@sebrae.com.br
                </li>
                <li style={{ paddingBottom: '5px', fontSize: '16px' }}>
                  {' '}
                  Telefone: (61) 3348-7100
                </li>
                <li style={{ paddingBottom: '5px', fontSize: '16px' }}>
                  Carta: SEBRAE Nacional – SGAS Quadra 605 Conjunto A –
                  Brasília/DF – CEP 70200-904.
                </li>
              </ul>
            </div>
          </Arquivos>
          <div id="sic-faq"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isContraste: store.principal.isContraste,
});

export default connect(mapStateToProps)(SicConteudo);
